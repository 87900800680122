
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import { BASE_URL } from '../config/apiConfig';
import Header from './Header'; 
import Redirect from "../../Components/Redirect";
const SidebarStyled = styled.div`
  position: fixed; 
  top: 0;
  left: 0;
  height: 100vh; 
  width: 250px; 
  background-color: #f4f4f4;
  overflow-y: auto; 
  z-index: 1000; 
`;
const MainContent = styled.div`
  margin-left: 250px; 
  padding: 20px;
  font-family: Arial, sans-serif;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const Th = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #f4f4f4;
`;
const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const DoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const jwtToken = sessionStorage.getItem('jwtToken');
  useEffect(() => {
    const fetchDoctors = async () => {
      if (!jwtToken) {
        setError('No JWT token available for authentication');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${BASE_URL}/user/doctors`, {
          headers: {
            'Auth': `Bearer ${jwtToken}`
          }
        });
        setDoctors(response.data);
      } catch (error) {
        setError('Error fetching doctors');
      } finally {
        setLoading(false);
      }
    };

    fetchDoctors();
  }, [jwtToken]); 

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="doctors-list">
        <Redirect />
      <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <MainContent>
        <h1>Doctors List</h1>
        <Table>
          <thead>
            <tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Mobile No</Th>
              <Th>Specialist</Th>
            </tr>
          </thead>
          <tbody>
            {doctors.map((doctor, index) => (
              <Tr key={index}>
                <Td>{doctor.name}</Td>
                <Td>{doctor.email}</Td>
                <Td>{doctor.mobileNo}</Td>
                <Td>{doctor.specialist}</Td>
              </Tr>
            ))}
          </tbody>
          
        </Table>
      </MainContent>
    </div>
  );
};

export default DoctorsList;
